@use "../../global/" as g;

.p-contact-form {
  .mw_wp_form .horizontal-item + .horizontal-item {
    margin-left: 0;
  }

  &__title {
    @include g.clamp-fz(20, 32, 1160);
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  &__text {
    font-size: g.rem(16);
    letter-spacing: 0.02em;
    @include g.lh(16, 32);
    margin-top: 15px;

    + .p-contact-form__text-note {
      margin-top: 14px;
    }
  }

  &__thanks-text {
    font-size: g.rem(16);
    font-weight: 500;

    @include g.mq(md) {
      text-align: center;
      @include g.clamp-fz(18, 20, 1160);
    }

    + .p-contact-form__thanks-text {
      font-size: g.rem(14);
      font-weight: 400;
      margin-top: 20px;

      @include g.mq(md) {
        font-size: g.rem(16);
        margin-top: 30px;
      }
    }
  }

  &__home-btn {
    margin-top: 30px;

    @include g.mq(md) {
      margin-top: 40px;
    }
  }

  &__body {
    margin-top: 130px;
    font-family: g.$NotoSans;
  }

  &__text-note {
    font-family: g.$NotoSans;
    font-size: g.rem(16);
    letter-spacing: 0.04em;
    font-weight: 500;
    @include g.lh(16, 32);
    color: g.$red;

    .u-required {
      font-family: g.$NotoSans;
      background-color: g.$red;
      color: g.$white;
      letter-spacing: 0.04em;
      padding: 2px 4px;
      margin-right: 10px;
    }
  }

  &__item {
    @include g.mq(md) {
      display: grid;
      align-items: center;
      grid-template-columns: 300px 1fr;
    }

    + .p-contact-form__item {
      margin-top: 40px;

      @include g.mq(md) {
        margin-top: 94px;
      }
    }

    &:nth-of-type(3) {
      margin-top: 60px;

      @include g.mq(md) {
        margin-top: 60px;
      }
    }

    &:nth-of-type(4) {
      @include g.mq(md) {
        margin-top: 110px;
      }

      .p-contact-form__item-note-area {
        margin-bottom: 0;
      }
    }

    &:nth-last-of-type(1) {
      align-items: flex-start;

      @include g.mq(md) {
        margin-top: 90px;
      }

      .p-contact-form__item-title {
        margin-top: 20px;
      }
    }

    &:nth-last-of-type(2) {
      align-items: flex-start;

      @include g.mq(md) {
        margin-top: 70px;
      }

      .p-contact-form__item-note-area {
        position: relative;
        bottom: auto;
      }
    }

    &:nth-last-of-type(3) {
      @include g.mq(md) {
        margin-top: 60px;
      }
    }
  }

  &__item-title {
    margin-right: 30px;
    font-size: g.rem(16);
    letter-spacing: 0.02em;
    font-weight: 500;
  }

  &__item-required {
    position: relative;

    &::after {
      content: "必須";
      font-family: g.$NotoSans;
      background-color: g.$red;
      color: g.$white;
      letter-spacing: 0.04em;
      padding: 2px 7px;
      font-size: g.rem(14);
      margin-left: 10px;
    }
  }

  &__item-any {
    position: relative;

    &::after {
      content: "任意";
      font-family: g.$NotoSans;
      background-color: g.$gray07;
      color: g.$white;
      letter-spacing: 0.04em;
      padding: 2px 7px;
      font-size: g.rem(14);
      margin-left: 10px;
    }
  }

  &__item-body {
    position: relative;

    input[type="text"],
    input[type="email"],
    textarea {
      border: 1px solid g.$border;
      background-color: g.$white;
      display: block;
      width: 100%;
      padding: 18px 10px;
      font-size: g.rem(16);
    }

    textarea {
      min-height: 280px;
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked {
      + .p-contact-form__item-body-input-radio-text {
        &::after {
          opacity: 1;
        }
      }

      + .mwform-radio-field-text {
        &::after {
          opacity: 1;
        }
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked {
      + .p-contact-form__item-body-input-radio-text {
        &::after {
          opacity: 1;
        }
      }

      + .mwform-checkbox-field-text {
        &::after {
          opacity: 1;
        }
      }
    }

    .mwform-radio-field-text,
    .mwform-checkbox-field-text {
      font-family: g.$NotoSans;
      padding-left: 24px;
      position: relative;
      font-size: g.rem(16);
      display: inline-block;
      letter-spacing: 0.02em;
      font-weight: 400;

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border: 1px solid g.$border;
        background-color: g.$white;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: calc(50% - 6px);
      }

      &::after {
        content: "";
        width: 8px;
        height: 8px;
        background-color: g.$blue02;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        left: 2px;
        top: calc(50% - 4px);
        opacity: 0;
      }
    }
  }

  &__item-note-area {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    @include g.mq(md) {
      position: absolute;
      bottom: 100%;
    }
  }

  &__item-example {
    font-size: g.rem(16);
    letter-spacing: 0.02em;
    color: g.$gray06;
  }

  &__item-note {
    font-size: g.rem(16);
    letter-spacing: 0.02em;
    color: g.$red;
    font-weight: 400;
  }

  &__item-body-input-radio {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 15px;
  }

  &__item-body-input-radio-text {
    font-family: g.$NotoSans;
    padding-left: 24px;
    position: relative;
    font-size: g.rem(16);
    display: inline-block;
    letter-spacing: 0.02em;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border: 1px solid g.$border;
      background-color: g.$white;
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: calc(50% - 6px);
    }

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background-color: g.$blue02;
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      left: 2px;
      top: calc(50% - 4px);
      opacity: 0;
    }
  }

  &__privacy-policy {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;

    .mwform-checkbox-field-text {
      display: inline-block;
      padding-left: 40px;
      position: relative;
      font-size: g.rem(16);
      letter-spacing: 0.02em;
      margin-top: 12px;

      &::before {
        content: "";
        display: inline-block;
        width: 26px;
        height: 26px;
        border: 1px solid g.$border;
        background-color: g.$white;
        position: absolute;
        left: 0;
        top: calc(50% - 13px);
      }

      &::after {
        content: "";
        width: 30px;
        height: 10px;
        border-left: 2px solid g.$blue02;
        border-bottom: 2px solid g.$blue02;
        transform: rotate(-45deg) skew(-45deg);
        position: absolute;
        left: 2px;
        top: calc(50% - 10px);
        opacity: 0;
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked {
      + .p-contact-form__privacy-policy-check-text {
        &::after {
          opacity: 1;
        }
      }

      + .mwform-checkbox-field-text {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__privacy-policy-text {
    font-size: g.rem(16);
    letter-spacing: 0.02em;
    font-family: g.$NotoSans;
  }

  &__privacy-policy-check-text {
    display: inline-block;
    padding-left: 40px;
    position: relative;
    font-size: g.rem(16);
    letter-spacing: 0.02em;
    margin-top: 12px;

    &::before {
      content: "";
      display: inline-block;
      width: 26px;
      height: 26px;
      border: 1px solid g.$border;
      background-color: g.$white;
      position: absolute;
      left: 0;
      top: calc(50% - 13px);
    }

    &::after {
      content: "";
      width: 30px;
      height: 10px;
      border-left: 2px solid g.$blue02;
      border-bottom: 2px solid g.$blue02;
      transform: rotate(-45deg) skew(-45deg);
      position: absolute;
      left: 2px;
      top: calc(50% - 10px);
      opacity: 0;
    }
  }

  &__btn-area {
    margin: 0 auto;
    margin-top: 60px;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__submit-btn {
    background-color: g.$blue02;
    border: 2px solid g.$blue02;
    border-radius: 40px;
    color: g.$white;
    font-size: g.rem(18);
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 20px 0;
    transition: 0.4s ease-in-out;

    &:hover {
      background-color: g.$white;
      color: g.$blue02;
    }
  }

  &__prev-btn {
    background-color: g.$white;
    border: 2px solid g.$blue02;
    border-radius: 40px;
    color: g.$blue02;
    font-size: g.rem(18);
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 10px 0;

    &:hover {
      background-color: g.$blue02;
      color: g.$white;
      transition: 0.4s ease-in-out;
    }
  }
}
