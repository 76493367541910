@use "../../global/" as g;

.l-contact-main {
  background-color: g.$gray03;
  padding-top: 30px;
  padding-bottom: 60px;

  @include g.mq(md) {
    padding-top: 50px;
    padding-bottom: 160px;
  }
}
